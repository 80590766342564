.resourceStyle{
    background-color: lightgray;
   /* color: white;*/
    padding: 40px;
    border: 1px solid black;
    /*text-align: center;*/
}


  
  
  
  