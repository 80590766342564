.margin-top {
    margin-top:  10px;
}
.half-height {
  margin-top: -15px;
}
.border {
    border: 1px solid black
}
.awards-gala {
  
  font-size: '50px';
}

