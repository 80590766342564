
.responsive-svg {
    width: 100%; 
    height: auto; 
    margin-top: -15px;
  }
.a_link {
    color: orange;    
}
.schedule-container {
    margin-left: -5px;
    align-content: center;
    min-height: 100%;

}
.tab-content {
   
    border-bottom: 1px solid #ddd;
   
}

.schedule {

    width: 100%;
    /* color: white;*/
    padding: 10px;
   
    align-content: center;
    /*text-align: center;*/
}

hr {
    width: 100%;
    color: lightgrey;
}

.dayone {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
}

.tuesday {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: -15px;
}

.title_bold {
    font-size: 16px;
    font-weight: 700;
}
.td1 {
    width: 29%;
}
.td2 {
    width: 47% ;
}
.td3 {
    width: 24% ;
}
.topic {
    font-weight: bold;
}
.speaker  {
   font-style: italic;
}

.italicized {
    font-style:  italic;
   
}
li {
    font-style: italic;

} 
.schedule-fontsize {
    font-size: 10px;
}
.schedule-agendas {
   font-size: 10px;
   
}
ul {
list-style-type: none;

}
.agendas {
    margin-left: -30px;
}
.reduce-top {
    margin-top: -15px;
}
.reduce-bottom {
    margin-bottom: 7px; 
}

@media (max-width: 768px) {
    
    .line-break {
      display: block; 
      margin-bottom: -5px;
    }
  }



  
  
  
  