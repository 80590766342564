a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: black;
 
}
a:hover {
    background-color: #ffc600;
}
.app-name {
    text-decoration: none;
    color: white;
}

html {
  font-size: 14px;
}


@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.nav-profile {
    margin-left: 11%;
    /*margin-top: 3%;*/
}
.kbhome-ncssc-mobile-logout {
    margin-left: 5px;
    font-size: 14px;
    margin-top: 6px;
}
.kbhome-ncssc-mobile {
    margin-left: 10px;
    font-size: 14px;


}
.header-mobile {
/* background-color: lightgray;*/
}
.footer-mobile {
    color: white;
    position: fixed;
    width: 100%;
    height: 87px;
    bottom: -22px;
    background: lightgray;
    font-size: 18px;
    
}
.mobile-navitem-border-bottom {
    border-bottom: 1px solid lightgray;
}
.caret-left-margin {
    left: 90%;

    position: absolute;
}
.profile {
    margin-right: -21px;
    height: 30px;
}
.profile-mobile-logged-out {
    height: 30px;
    margin-left: 65px;
    margin-top: -9px;
}

.profile-desktop-logged-in  {

    margin-right: -18px;

    height: 30px;
    display: inline-block;
}

.profile-desktop-logged-out {
    margin-right: 5px;
    height: 30px;
    display: inline-block;
    margin-top: -12px;
}
.desktop-login {
    display: inline-block;
    margin-left: -16px;
    color: black;
    font-weight: bold;
}

#basic-nav-dropdown {
    margin-left: -11px;
    color: black;
    font-weight: bold;
    margin-top: 1px;
}
