nav.slick__arrow, ul.slick-dots {
    display: none !important;
}
.carousel-img {
    width: 100%;
    height: 500px; 
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .carousel-img {
      height: 350px; 
    }
  }