.home-tiles {
    text-align: center;

}
.center {
    margin-left: 10%;
    width: 100%
}
.customDeskTop {
    width: 185px;
    height: 175px;
    

}
.tiles {
    width: 100%;
    padding: 0px !important;
    border-radius: 15px;
    
}
.tiles-title-mobile{
    /* margin-left: 25%; */
    font-size: 10px;
}
a:hover {
   background-color: lightgray !important; 
}
.customMobile {
    width: 120px;
    height: 108px;

    font-size: 8px;

}

.td {
    padding: 0px;
}

.flex_grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 20px;
  
}
.desktop_maxwidth {
    max-width: 39% !important;
    margin-left: 33%;
}
.mobile_maxwidth {
    margin-left: 2%;
    max-width: 97%;
}
.btn {

    padding: 4px !important;
}