form {
    background-color: #ffe27f;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    padding: 30px;
}
.login-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}
.login-button {
    margin-left: 35%;
    margin-top: 20px;
    width: 100px;
}
.masthead {
    min-height: 30vh;
    position: relative;

}
.c19-message-desktop {
    z-index: 99;
    background: lightgrey;
    padding-top: 100px;
    position: absolute;
    width: 100%;
    height: auto;
    margin-left: 0%;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.4);
    padding: 30px;
  
}

.c19-message-mobile {
    z-index: 99;
    background: lightgrey;
    position: absolute;
    width: 100%;
    height: auto;

    box-shadow: 10px 10px 10px rgb(0 0 0 / 40%);
    padding: 30px;
}
.login-title {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 20px;
}
.login-message {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.cookie-policy {
    padding-top: 70px;
    line-height: 1px;
    font-size: 10px;
}

