.outer {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;         
    height: auto;
    align-content: center;
    border: 1px solid gray; 
    margin-bottom: 20px;
  }


