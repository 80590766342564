body {
  margin: 0;

    font-family: Arial !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-link-award {
  font-size: 55px;
  font-family: 'Arizonia';
}
.font-link-thriller {
  font-size: 70px;
  font-family: 'Chiller';
}

.App {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  
 /*  //margin-top: 5% !important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

form {
    background-color: #ffe27f;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    padding: 30px;
}
.login-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}
.login-button {
    margin-left: 35%;
    margin-top: 20px;
    width: 100px;
}
.masthead {
    min-height: 30vh;
    position: relative;

}
.c19-message-desktop {
    z-index: 99;
    background: lightgrey;
    padding-top: 100px;
    position: absolute;
    width: 100%;
    height: auto;
    margin-left: 0%;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.4);
    padding: 30px;
  
}

.c19-message-mobile {
    z-index: 99;
    background: lightgrey;
    position: absolute;
    width: 100%;
    height: auto;

    box-shadow: 10px 10px 10px rgb(0 0 0 / 40%);
    padding: 30px;
}
.login-title {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 20px;
}
.login-message {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.cookie-policy {
    padding-top: 70px;
    line-height: 1px;
    font-size: 10px;
}


.home-tiles {
    text-align: center;

}
.center {
    margin-left: 10%;
    width: 100%
}
.customDeskTop {
    width: 185px;
    height: 175px;
    

}
.tiles {
    width: 100%;
    padding: 0px !important;
    border-radius: 15px;
    
}
.tiles-title-mobile{
    /* margin-left: 25%; */
    font-size: 10px;
}
a:hover {
   background-color: lightgray !important; 
}
.customMobile {
    width: 120px;
    height: 108px;

    font-size: 8px;

}

.td {
    padding: 0px;
}

.flex_grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 20px;
  
}
.desktop_maxwidth {
    max-width: 39% !important;
    margin-left: 33%;
}
.mobile_maxwidth {
    margin-left: 2%;
    max-width: 97%;
}
.btn {

    padding: 4px !important;
}
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: black;
 
}
a:hover {
    background-color: #ffc600;
}
.app-name {
    text-decoration: none;
    color: white;
}

html {
  font-size: 14px;
}


@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.nav-profile {
    margin-left: 11%;
    /*margin-top: 3%;*/
}
.kbhome-ncssc-mobile-logout {
    margin-left: 5px;
    font-size: 14px;
    margin-top: 6px;
}
.kbhome-ncssc-mobile {
    margin-left: 10px;
    font-size: 14px;


}
.header-mobile {
/* background-color: lightgray;*/
}
.footer-mobile {
    color: white;
    position: fixed;
    width: 100%;
    height: 87px;
    bottom: -22px;
    background: lightgray;
    font-size: 18px;
    
}
.mobile-navitem-border-bottom {
    border-bottom: 1px solid lightgray;
}
.caret-left-margin {
    left: 90%;

    position: absolute;
}
.profile {
    margin-right: -21px;
    height: 30px;
}
.profile-mobile-logged-out {
    height: 30px;
    margin-left: 65px;
    margin-top: -9px;
}

.profile-desktop-logged-in  {

    margin-right: -18px;

    height: 30px;
    display: inline-block;
}

.profile-desktop-logged-out {
    margin-right: 5px;
    height: 30px;
    display: inline-block;
    margin-top: -12px;
}
.desktop-login {
    display: inline-block;
    margin-left: -16px;
    color: black;
    font-weight: bold;
}

#basic-nav-dropdown {
    margin-left: -11px;
    color: black;
    font-weight: bold;
    margin-top: 1px;
}


.responsive-svg {
    width: 100%; 
    height: auto; 
    margin-top: -15px;
  }
.a_link {
    color: orange;    
}
.schedule-container {
    margin-left: -5px;
    align-content: center;
    min-height: 100%;

}
.tab-content {
   
    border-bottom: 1px solid #ddd;
   
}

.schedule {

    width: 100%;
    /* color: white;*/
    padding: 10px;
   
    align-content: center;
    /*text-align: center;*/
}

hr {
    width: 100%;
    color: lightgrey;
}

.dayone {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
}

.tuesday {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: -15px;
}

.title_bold {
    font-size: 16px;
    font-weight: 700;
}
.td1 {
    width: 29%;
}
.td2 {
    width: 47% ;
}
.td3 {
    width: 24% ;
}
.topic {
    font-weight: bold;
}
.speaker  {
   font-style: italic;
}

.italicized {
    font-style:  italic;
   
}
li {
    font-style: italic;

} 
.schedule-fontsize {
    font-size: 10px;
}
.schedule-agendas {
   font-size: 10px;
   
}
ul {
list-style-type: none;

}
.agendas {
    margin-left: -30px;
}
.reduce-top {
    margin-top: -15px;
}
.reduce-bottom {
    margin-bottom: 7px; 
}

@media (max-width: 768px) {
    
    .line-break {
      display: block; 
      margin-bottom: -5px;
    }
  }



  
  
  
  
.margin-top {
    margin-top:  10px;
}
.half-height {
  margin-top: -15px;
}
.border {
    border: 1px solid black
}
.awards-gala {
  
  font-size: '50px';
}


.hotel-reservation-table {
  margin-left: auto;
  margin-right: auto;
  margin-top: -15px;
}
.half-height {
  margin-top: -15px;
}
.resourceStyle{
    background-color: lightgray;
   /* color: white;*/
    padding: 40px;
    border: 1px solid black;
    /*text-align: center;*/
}


  
  
  
  
.outer {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;         
    height: auto;
    align-content: center;
    border: 1px solid gray; 
    margin-bottom: 20px;
  }



nav.slick__arrow, ul.slick-dots {
    display: none !important;
}
.carousel-img {
    width: 100%;
    height: 500px; 
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .carousel-img {
      height: 350px; 
    }
  }
